@import '../node_modules/@angular/material/prebuilt-themes/indigo-pink.css';
@import "../node_modules/leaflet/dist/leaflet.css";
@import 'assets/flag-icon-css-master/css/flag-icon.min.css';

body {
    margin: 0px;
    height: 100vh;
    padding:0px;
}

